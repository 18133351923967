@import "../Variables/variables";

.navigation_bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: .5px solid $links_color;
    background: #0077b6;
    padding: 30px 80px;
    transition: 0.3s;
    top: 0;
    z-index: 99;
    position: fixed;

    .logo {
        width: 140px;
        height: 140px;
        transition: all 1s;


        img {
            width: 100%;
            height: 100%;
            border-radius: 20%;

        }
    }

    .logo_fixed {
        width: 100px;
        height: 100px;
        transition: all 1s;


        img {
            width: 100%;
            height: 100%;
            border-radius: 20%;
        }
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        color: $links_color;
        font-size: 30px;
        font-weight: 500;
        cursor: pointer;

        .link {
            text-decoration: none;
            color: $links_color;
            transition: all 0.5s;

            &:hover {
                color: #fff;
            }

            .link_icon {
                display: none;
            }
        }

    }

    .social_media {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .icon {
            font-size: 40px;
            cursor: pointer;
        }

        .instagram_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            transition: 0.3s ease-in-out;
            height: 100%;



            &:hover {
                color: $insta_color;
                font-size: 40px;
            }
        }

        .whatsapp_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            transition: 0.3s ease-in-out;
            height: 100%;

            &:hover {
                color: $whatsapp_color;
                font-size: 40px;
            }
        }

        .phone_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            transition: 0.3s ease-in-out;
            height: 100%;

            &:hover {
                font-size: 40px;
            }
        }
    }
}

.navbar_fixed {
    padding: 10px 80px;
    /* Other fixed position styles */
}

.logo_fixed {
    width: 100px;
    height: auto;
    margin: 5px 10px;
}

@media screen and (min-width:280px) {
    .navigation_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 30px;

        .logo {
            width: 60px;
            height: 60px;
            // margin-bottom: 16px;
        }

        .links {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            position: fixed;
            left: 0;
            bottom: 0;
            border-top: .5px solid $links_color;
            justify-content: space-around;
            background-color: #fff;
            z-index: 1;

            .link {
                font-size: 20px;

                .link_text {
                    display: none;
                }

                .link_icon {
                    display: block;
                }
            }
        }

        .social_media {

            .icon {
                font-size: 32px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width:769px) {
    .navigation_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;


        .logo {
            width: 120px;
            height: 120px;
            margin-bottom: 16px;
        }

        .links {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            justify-content: space-around;
            background: transparent;
            border: none;


            .link {
                font-size: 16px;

                .link_text {
                    display: block;
                }

                .link_icon {
                    display: none;
                }
            }
        }

        .social_media {

            .icon {
                font-size: 34px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width:1025px) {
    .navigation_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;


        .logo {
            width: 130px;
            height: 130px;
            margin-bottom: 16px;
        }

        .links {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            justify-content: space-between;
            background: transparent;
            border: none;


            .link {
                font-size: 18px;

                .link_text {
                    display: block;
                }

                .link_icon {
                    display: none;
                }
            }
        }

        .social_media {

            .icon {
                font-size: 34px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width:1201px) {
    .navigation_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;


        .logo {
            width: 140px;
            height: 140px;
        }

        .links {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            justify-content: space-between;
            background: transparent;
            border: none;


            .link {
                font-size: 22px;

                .link_text {
                    display: block;
                }

                .link_icon {
                    display: none;
                }
            }
        }

        .social_media {

            .icon {
                font-size: 34px;
                cursor: pointer;
            }
        }
    }
}