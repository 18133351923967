@import "../Variables/variables";

// mx-auto h-80 max-w-lg overflow-y-scroll bg-cover bg-fixed bg-center bg-no-repeat shadow-lg
.footer {
    background-color: $footer_bg;
    text-align: left;

    .footer_body {
        text-align: left;
        padding: 40px;
        margin: 0 24px;
        color: #fff;

        .grid_container {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 1rem;

            .grid_item_1 {
                .logo {
                    width: 140px;
                    height: 140px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 20%;
                    }
                }
            }

            .grid_item_2 {
                h6 {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                p {
                    margin-bottom: 8px;
                }
            }

            .grid_item_3 {
                h6 {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                p {
                    margin-bottom: 8px;
                }
            }

            .grid_item_4 {
                h6 {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                p {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 8px;

                    a {
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        margin-right: 12px;
                        // height: 50px;
                        width: auto;
                    }
                }
            }
        }

    }

    .footer_copyrights {
        text-align: center;
        padding: 24px;
        color: #fff;
        border-top: 1px solid #fff;
    }
}

@media screen and (min-width:280px) {
    .footer {
        background: $footer_bg;
        text-align: center;
        margin-bottom: 50px;

        .footer_body {
            text-align: center;
            padding: 40px;
            margin: 0 24px;

            .grid_container {
                display: grid;
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 1rem;

                .grid_item_1 {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .grid_item_2 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    p {
                        margin-bottom: 8px;
                    }
                }

                .grid_item_3 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    p {
                        margin-bottom: 8px;
                    }
                }

                .grid_item_4 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    p {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 8px;
                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            span{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .footer_copyrights {
            margin: 0 24px;
            text-align: center;
            padding: 36px;
        }
    }
}

@media screen and (min-width:769px) {
    .footer {
        background: $footer_bg;
        text-align: center;
        margin-bottom: 0;

        .footer_body {
            text-align: center;
            padding: 20px;
            margin: 0 24px;

            .grid_container {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 3fr));
                gap: 1rem;

                .grid_item_1 {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .grid_item_2 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;
                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 10px;
                    }
                }

                .grid_item_3 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;

                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 10px;
                    }
                }

                .grid_item_4 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;

                    }
                    p {

                        margin-bottom: 8px;
                        font-size: 10px;

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            span{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .footer_copyrights {
            margin: 0 24px;
            text-align: center;
            padding: 30px;
            font-size: 10px;
        }
    }

}

@media screen and (min-width:920px) {
    .footer {
        background: $footer_bg;
        text-align: center;
        margin-bottom: 0;

        .footer_body {
            text-align: center;
            padding: 20px;
            margin: 0 24px;

            .grid_container {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 3fr));
                gap: 1rem;

                .grid_item_1 {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .grid_item_2 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight:bold;
                        font-size: 16px;
                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 12px;
                    }
                }

                .grid_item_3 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 16px;

                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 12px;
                    }
                }

                .grid_item_4 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 16px;

                    }
                    p {

                        margin-bottom: 8px;
                        font-size: 12px;

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            span{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .footer_copyrights {
            margin: 0 24px;
            text-align: center;
            padding: 36px;
            font-size: 12px;
        }
    }

}

@media screen and (min-width:1201px) {
    .footer {
        background: $footer_bg;
        text-align: center;
        margin-bottom: 0;

        .footer_body {
            text-align: center;
            padding: 20px;
            margin: 0 24px;

            .grid_container {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 3fr));
                gap: 1rem;

                .grid_item_1 {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .grid_item_2 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight:bold;
                        font-size: 18px;
                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 16px;
                    }
                }

                .grid_item_3 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 16px;

                    }

                    p {
                        margin-bottom: 8px;
                        font-size: 16px;
                    }
                }

                .grid_item_4 {
                    h6 {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 18px;

                    }
                    p {

                        margin-bottom: 8px;
                        font-size: 16px;

                        a {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            span{
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        .footer_copyrights {
            margin: 0 24px;
            text-align: center;
            padding: 36px;
            font-size: 16px;

            .copyrights {
                transition: all 0.3s;
                &:hover {
                    font-size: 20px;
                    
                }
            }
        }
    }

}