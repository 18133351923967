.services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 80px;

    h2 {
        font-size: 100px;
        margin-bottom: 20px;
        color: #000;

        span {
            color: #0077b6;
        }
    }

    .card_container {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        width: 100%;


        .card_item {
            display: flex;
            padding: 2rem;
            width: 33.3333%;
            height: auto;


            .card {
                position: relative;
                display: flex;
                border-radius: 12px;
                font-size: 30px;
                text-align: center;
                overflow: hidden;
                box-shadow: 0px 0px 20px 8px rgba(89, 86, 89, 0.4);
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 12px;
                    opacity: 0.8;
                    transition: all 0.3s;


                    &:hover {
                        opacity: 1;
                    }

                }

            }

            .title {
                font-size: 40px;
                font-weight: 600;
                color: #0077b6;
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 0;
                padding: 100px 25px;
                height: 200px;
                width: 100%;
                text-align: end;
                background: linear-gradient(to top, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
            }


        }
    }
}

@media screen and (min-width:280px) and (max-width:480px) {
    .services {
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 50px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }


        .card_container {
            display: flex;
            justify-content: center;

            .card_item {
                width: 100%;
                padding: 1rem;

                .card {
                    width: 500px;
                    height: auto;


                    img {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 12px;
                    text-align: end;
                    height: 60px;
                    padding: 30px 16px;
                }
            }


        }
    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .services {
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }


        .card_container {
            display: flex;
            justify-content: center;

            .card_item {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 1rem;

                .card {
                    width: 500px;
                    height: auto;


                    img {
                        width: 100%;
                        height: 400px;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 24px;
                    text-align: end;
                    height: 80px;
                    padding: 30px 16px;
                }
            }


        }
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .services {
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }


        .card_container {
            display: flex;
            justify-content: center;

            .card_item {
                display: flex;
                justify-content: center;
                width: 50%;
                padding: 1rem;

                .card {
                    width: 500px;
                    height: auto;


                    img {
                        width: 100%;
                        height: 420px;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 30px;
                    text-align: end;
                    height: 100px;
                    padding: 40px 16px;
                }
            }


        }
    }
}


@media screen and (min-width:1024px) and (max-width:1200px) {
    .services {
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }


        .card_container {
            display: flex;
            justify-content: center;

            .card_item {
                display: flex;
                justify-content: center;
                width: 50%;
                padding: 1rem;

                .card {
                    width: 500px;
                    height: auto;


                    img {
                        width: 100%;
                        height: 420px;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 30px;
                    text-align: end;
                    height: 100px;
                    padding: 40px 16px;
                }
            }


        }
    }
}

@media screen and (min-width:1201px) {
    .services {
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 80px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }


        .card_container {
            display: flex;
            justify-content: center;

            .card_item {
                display: flex;
                justify-content: center;
                width: 33.333%;
                padding: 1rem;

                .card {
                    width: 500px;
                    height: auto;


                    img {
                        width: 100%;
                        height: 460px;
                        object-fit: cover;
                    }
                }

                .title {
                    font-size: 36px;
                    text-align: end;
                    height: 120px;
                    padding: 40px 20px;
                }
            }


        }
    }
}