.review_card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 200px;
    gap: 10px;
    border-radius: 12px;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

    .image_container {
        flex-basis: 40%;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 0 auto;

        img {
            clip-path: circle(39% at 50% 50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            position: relative;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        flex-basis: 60%;

        span {
            text-transform: capitalize;
        }
    }


}