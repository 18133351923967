.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 80px;

    h2 {
        font-size: 100px;
        margin-bottom: 20px;
        color: #000;

        span {
            color: #0077b6;
        }
    }

    .gallery_images {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        width: 100%;

        .gallery_image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            width: 33.3333%;
            height: auto;

            .img {
                object-fit: cover;
                opacity: 1;
                cursor: pointer;
                border-radius: 16px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.hidden_img {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100px);
    transition: all 2s;
}

.visible_img {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}




@media screen and (min-width:280px) and (max-width:480px) {
    .gallery {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px 40px;

        h2 {
            text-align: center;
            font-size: 50px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }

        .gallery_images {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;

            .gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                width: 100%;
                height: auto;

                .img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    border-radius: 16px;
                    background: blue;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .gallery {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px 40px;

        h2 {
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }

        .gallery_images {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;

            .gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                width: 100%;
                height: auto;

                .img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    border-radius: 16px;
                    background: blue;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .gallery {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px 80px;

        h2 {
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }

        .gallery_images {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;

            .gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                width: 50%;
                height: auto;

                .img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    border-radius: 16px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

    }

}

@media screen and (min-width:1025px) and (max-width:1200px) {
    .gallery {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px 80px;

        h2 {
            font-size: 60px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }

        .gallery_images {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;

            .gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                width: 50%;
                height: auto;

                .img {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    border-radius: 16px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

    }
}

@media screen and (min-width:1201px) {
    .gallery {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 50px 80px;

        h2 {
            font-size: 80px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }

        .gallery_images {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            width: 100%;

            .gallery_image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                width:33.33%;
                height: auto;

                .img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    opacity: 1;
                    cursor: pointer;
                    border-radius: 16px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

    }
}