.about {
    display: flex;
    margin: 120px 80px;

    .about_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .title {
            font-size: 100px;
            margin-bottom: 20px;
            color: #000;

            span {
                color: #0077b6;
            }
        }
        .text {
            font-size: 30px;
            margin-top: 0;
            width: 90%;
            color: #3d405b;

        }
    }

    .about_image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        

        img {
            width: 100%;
            height: auto;
            border-radius: 16px;
        }
    }

}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.hidden_img {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(200px);
    transition: all 2s;
}

.visible_img {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

@media screen and (max-width:1366px) {
    .about {
        .about_text {
            .title {
                font-size: 80px;
                span {
                    color: #0077b6;
                }
            }
            .text {
                font-size: 22px;
                width: 90%;
            }
        }
    }
}


@media screen and (max-width:980px) {

    .about {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 50px 80px;

        .about_text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            h2 {
                font-size: 50px;
                margin-bottom: 20px;
                color: #000;

                span {
                    color: #0077b6;
                }
            }

            p {
                font-size: 16px;
                margin-top: 0;
                width: 100%;
                color: #3d405b;

            }

            button {
                width: 200px;
                border: none;
                color: #ffffff;
                padding: 12px 0px;
                border-radius: 40px;
                font-size: 20px;
                background: #3d405b;
                cursor: pointer;
                transition: .5s ease-in-out;

                &:hover {
                    background: #0077b6;
                }

            }
        }

        .about_image {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }

    }

}

@media screen and (max-width:768px) {

    .about {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 50px 40px;

        .about_text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .title {
                font-size: 50px;
                margin-bottom: 16px;
                color: #000;

                span {
                    color: #0077b6;
                }
            }

            .text {
                font-size: 14px;
                margin-top: 0;
                width: 100%;
                color: #3d405b;

            }

        }

        .about_image {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }

    }

}