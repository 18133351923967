.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75vh;
    background: #0077b6;
    margin-top: 93px;

    .hero_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 50px;

        .title {
            color: #fff;
            font-size: 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            text-align: center;

            span {
                color: #8d99ae;
                // margin-left: 20px;
                transition: 0.3s ease-in-out;
                cursor: pointer;
                text-align: center;


                &:hover {
                    font-size: 54px;
                }
            }
        }
    }

    .hero_right {
        width: 100%;
        height: 100%;
        display: none;


        img {
            clip-path: circle(39% at 50% 50%);
            width: 100%;
            height: auto;
        }
    }
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

@media screen and (min-width:769px) {
    .hero {
        margin-top: 169px;

        .hero_left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 50px;

            .title {
                color: #fff;
                font-size: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-self: center;
                text-align: center;

                span {
                    color: #8d99ae;
                    transition: 0.3s ease-in-out;
                    cursor: pointer;
                    text-align: center;


                    &:hover {
                        font-size: 64px;
                    }
                }
            }
        }

        .hero_right {
            display: none;
        }
    }

}

@media screen and (min-width:1025px) {
    .hero {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80vh;
        background: #0077b6;

        .hero_left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            padding: 0 50px;

            .title {
                color: #fff;
                font-size: 62px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-self: center;
                text-align: start;

                span {
                    color: #8d99ae;
                    transition: 0.3s ease-in-out;
                    cursor: pointer;
                    text-align: center;
                    margin-left: 20px;


                    &:hover {
                        font-size: 66px;
                    }
                }
            }
        }

        .hero_right {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                clip-path: circle(39% at 50% 50%);
                width: 95%;
                height: auto;
            }
        }
    }

}
@media screen and (min-width:1200px) {
    .hero {

        .hero_left {

            .title {
                font-size: 70px;
                span {
                    &:hover {
                        font-size: 76px;
                    }
                }
            }
        }

        .hero_right {
            margin-top: 20px;

            img {
                width: 90%;
                height: auto;
            }
        }
    }

}

@media screen and (min-width:1366px) {
    .hero {

        .hero_left {

            .title {
                font-size: 78px;
                span {
                    &:hover {
                        font-size: 82px;
                    }
                }
            }
        }

        .hero_right {
            margin-top: 20px;

            img {
                width: 90%;
                height: auto;
            }
        }
    }

}
